import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from "../components/Header";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";
import { getSyllabus } from '../Service/Api';
import LazyLoad from 'react-lazyload';

const Syllabus = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const syllabusData1 = await getSyllabus();
            const syllabusData = syllabusData1.filter((item) => item.category === "Syllabus")
            setData(syllabusData);
        };
        fetchData();
    }, []);

    return (
        <>
            <Header />
            <Breadcrumb mid="Academics" breadcrumbName="Syllabus" />
            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title">
                                <div>
                                    <h1>Syllabus</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {data.length > 0 ? (
                            data.map((item, index) => (
                                <div key={index} className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                    <div className="video-gallery syllabus-tab">
                                        <Link to={`https://webapi.entab.info/api/image/${item?.attachments[0]}`} target="_blank" rel="noreferrer">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/pdf.png" alt="St. mary school, mirzapur" /></LazyLoad>
                                            <div>
                                                <h3>{item.title}</h3>
                                                <p>{item.date}</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                <div className="video-gallery syllabus-tab">
                                    <Link to="#" target="_blank" rel="noreferrer">
                                        <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/pdf.png" alt="St. mary school, mirzapur" /></LazyLoad>
                                        <div>
                                            <h3>Syllabus Title</h3>
                                            <p>DD MM YYYY</p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Syllabus;
