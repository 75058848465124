import React, { useEffect, useState } from "react"; 
import LazyLoad from "react-lazyload";
 import { getNews } from "../Service/Api";
import {Link } from 'react-router-dom'
const News = () => {
    const [data, setData] = useState([]);

     useEffect(() => {
       const getData = async () => {
         try {
           const datas = await getNews();
           setData(datas);
         } catch (error) {
           console.error("Failed to fetch news:", error);
           setData([]); // Set to empty to ensure the empty state is handled
         }
       };
       getData();
     }, []);

    return (
        <>
            <marquee scrollamount="2" scrolldelay="30" direction="up" align="left" onmouseover="stop();" onmouseout="start();">
               {data.length > 0 ? (
                    data.map((item, index) => (  
                        <div className='newsarea' key={index}>
                            <div>
                            <p><b>{item.title}</b></p>
                            <p className='newshead'>{item.description}</p>
                            </div>
                                {item.attachments && item.attachments[0] && ( 
                                    <Link to={`https://webapi.entab.info/api/image/${item?.attachments}`} target='_blank'  rel="noreferrer">
                                       <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/attachment.png" /></LazyLoad>
                                    </Link>
                                   )} 
                            
                        </div>
                        
                    ))
                ) : (
                    <div className='newsarea'>
                            <div>
                            <p><b>News title</b></p>
                            <p className='newshead'>Stay Tuned For More Updates</p>
                            </div> 
                                    <Link to="/" target='_blank'>                                        
                                       <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/attachment.png" /></LazyLoad>
                                    </Link> 
                         
                        </div>
                )}  
            </marquee>
        </>
    );
};

export default News;
