import React from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload';
 
const Emagazine = () => {
   
    return (
        <>
            <Header />
            <Breadcrumb mid="Gallery" breadcrumbName="Magazine" />
            <div className="innerSection">
                <div className="container">
                    <div className="col-md-12">
                        <div className="title">
                            <div>
                                <h1>Magazine</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-lg-3 col-md-6'>
                            <div className="flip-block showflip" data-trigger="1">
                                 
                                <h3>2023-2024</h3>
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-1.jpg" alt=""></img></LazyLoad>
                                   
                                <div className="magazine-download">
                                    <div><Link to="/Magazine2024" target="_blank"  rel="noreferrer">View </Link></div>
                                    {/* <div><a href="https://webapi.entab.info/api/image/SMSMU/public/pdf/2023-24.pdf" download>Download</a></div> */}
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6'>
                            <div className="flip-block showflip" data-trigger="1">
                                 
                                <h3>2022-2023</h3>
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-c1.jpg" alt=""></img></LazyLoad>
                                   
                                <div className="magazine-download">
                                    <div><Link to="/Magazine2023" target="_blank"  rel="noreferrer">View </Link></div>
                                    {/* <div><a href="https://webapi.entab.info/api/image/SMSMU/public/pdf/2023-24.pdf" download>Download</a></div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Emagazine