import React, { useEffect, useRef, useState } from 'react'
import { getHighlight } from '../Service/Api';
import {Link} from 'react-router-dom'
function Highlights() {
 
  const marqueeRef = useRef(null);
  const handleMouseOver = () => {
    marqueeRef.current.stop();
  };
  const handleMouseOut = () => {
    marqueeRef.current.start();
  };
   
    const [data, setData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      const data = await getHighlight();
      console.log(data);
      setData(data);
    };
    getData();
  }, []);

 


  return (
 <marquee ref={marqueeRef} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} direction="up">
        {data.length > 0 ? (data.map((item, index) => (
           <p key={index}>
          {item.title}
          {item.attachments && item.attachments.length > 0 && (<Link to={`https://webapi.entab.info/api/image/${item.attachments[0]}`} target="_blank"  rel="noreferrer">  
          <i className="bi bi-paperclip"></i></Link>)}
       
   
</p>   ))
          ) : (
            <p>Stay Tuned For More Updated</p>
            )
            }
   

    </marquee>
  )
}

export default Highlights