import { Link } from 'react-router-dom' 
const Copyright = () => {
    const currentYear = new Date().getFullYear(); 
    return(
        <>
            <div className='copyright'>
                <p>© Copyright 2020 - {currentYear} St. Mary's School & College Mirzapur, Uttar Pradesh. All Rights Reserved. | Created By <Link to="https://entab.in" target="_blank" rel="noreferrer">Entab Infotech : CampusCare®</Link> </p>
            </div>
        </>
    )
}
export default Copyright