import React, { useEffect, useState } from 'react';
import { getBirthdays, getBirthdaysAll } from '../Service/Api';
import Slider from 'react-slick';
import '../Assets/css/slick.css'
import '../Assets/css/slick-theme.css'
import LazyLoad from 'react-lazyload';

const Birthday = () => {

    const [data, setData] = useState([]); // State for today's birthdays
    const [dataAll, setDataAll] = useState(); // State for all birthdays this month

    useEffect(() => {
        const fetchData = async () => {
            const birthdayData = await getBirthdays();
            setData(birthdayData);
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchDataAll = async () => {
            const birthdayDataAll = await getBirthdaysAll();
            const filterData = birthdayDataAll.filter((item) => {
                const month = new Date(item.date).getMonth();
                const currentMonth = new Date().getMonth();
                const getThisMonth = month === currentMonth
                return getThisMonth;
            })
            setDataAll(filterData);
            console.log("get all birthday", filterData)
        };
        fetchDataAll();
       
    }, []);
       
    const settings = {
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            { breakpoint: 1600, settings: { slidesToShow: 4 } },
            { breakpoint: 1200, settings: { slidesToShow: 3 } },
            { breakpoint: 768, settings: { slidesToShow: 4 } },
            { breakpoint: 567, settings: { slidesToShow: 2 } },
            { breakpoint: 500, settings: { slidesToShow: 1 } }
        ]
    };
    const settings1 = {
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            { breakpoint: 1600, settings: { slidesToShow: 2 } },
            { breakpoint: 1200, settings: { slidesToShow: 2 } },
            { breakpoint: 768, settings: { slidesToShow: 2 } },
            { breakpoint: 567, settings: { slidesToShow: 2 } },
            { breakpoint: 500, settings: { slidesToShow: 1 } }
        ]
    };

     
   
    return (
        <>
            <div className='col-md-6'>
                <div className='birthday-block'>
                    <div className="upcoming">
                        <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/candle.gif" /></LazyLoad>
                        <h4>TODAY STAR</h4>
                    </div>
                    <Slider {...settings1}>
                        {data.length > 0 ? data.map((item, index) => (
                            <div className="item" key={index}>
                                <div className="birthday-content">

                                     <img src={`https://webapi.entab.info/api/image/${item.attachments}`} alt="Birthday" /> 
                                    <p>{item.name}</p>
                                    <p className="primary">{item.class}</p>
                                </div>
                            </div>
                        )) : (<div className="item">
                            <div className="birthday-content">
                                <img src="https://webapi.entab.info/api/image/SMSMU/public/Images/birthday-dummy.png" alt="Birthday" />
                                <p>Student Name</p>
                                <p className="primary">Class</p>
                            </div>
                        </div>)}
                        </Slider>
                </div>
            </div>

            <div className='col-md-6'>
                <div className="upcoming addColor">
                    <h4>Birthday In<br></br> This Month</h4>
                </div>
                <Slider {...settings}>                        
                {dataAll ? dataAll.map((item, index) => (
    <div className="item" key={index}>
        <div className="birthday-content">
        <h6>{new Date(item.date).toLocaleDateString('en-GB', { day: '2-digit', month: 'short' })}</h6>

           
                <img src={`https://webapi.entab.info/api/image/${item.attachments}`} alt="Birthday" />
            
            <p>{item.name}</p>
            <p className="primary">{item.class}</p>
        </div>
    </div>
)) : (
    <div className="item">
        <div className="birthday-content">
            <h6>DD.MM</h6>
            <img src="https://webapi.entab.info/api/image/SMSMU/public/Images/birthday-dummy.png" alt="Birthday" />
            <p>Student Name</p>
            <p className="primary">Class</p>
        </div>
    </div>
)}

</Slider>
            </div>

        </>
    )
}
export default Birthday