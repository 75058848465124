import Header from "../components/Header"
import Footer from "../components/Footer"
import Breadcrumb from "../components/Breadcrumb"
import LazyLoad from "react-lazyload"
import { useEffect, useState } from "react"
const Laboratory = () => {
    const [selectedTab, setSelectedTab] = useState("biology");
    useEffect(() => {

    })
    return (
        <>
            <Header />
            <Breadcrumb mid="Facilities" breadcrumbName="Laboratory" />

            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title">
                                <div>
                                    <h1>Laboratory</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <p>The school has an up – to – date Lab Facility for the students. We have 4 Computer Labs, with online ups for power backups, and One Physics, Chemistry and Biology Lab to help the students in their practical work.</p>
                        </div>
                    </div>
                    <div class="row">

                        <div className="col-lg-3">
                            <button className={`selectionTabs ${selectedTab === "biology" ? "active":""}`} onClick={()=>setSelectedTab("biology")}>Biology  Lab</button>
                            <button  className={`selectionTabs ${selectedTab === "chemistry" ? "active":""}`} onClick={()=>setSelectedTab("chemistry")}>Chemistry  Lab</button>
                            <button className={`selectionTabs ${selectedTab === "physics" ? "active":""}`}  onClick={()=>setSelectedTab("physics")}>Physics Lab</button>
                            <button className={`selectionTabs ${selectedTab === "jamecomputer" ? "active":""}`}  onClick={()=>setSelectedTab("jamecomputer")}>St. Jame's Computer Lab</button>
                            <button className={`selectionTabs ${selectedTab === "johncomputer" ? "active":""}`}  onClick={()=>setSelectedTab("johncomputer")}>St. John's Computer Lab</button>
                            <button className={`selectionTabs ${selectedTab === "petercomputer" ? "active":""}`}  onClick={()=>setSelectedTab("petercomputer")}>St. Peter's Computer Lab</button>
                            <button className={`selectionTabs ${selectedTab === "franciscomputer" ? "active":""}`}  onClick={()=>setSelectedTab("franciscomputer")}>St. Francis' Computer Lab</button>
                        </div>
                        <div className="col-lg-9">
                            {selectedTab === "biology" && (
                                <div className="row">


                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-4 text-center">
                                        <h3>Biology  Lab</h3>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                        <div class="labs-box">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/Bio-Lab.jpg" alt="St Mary School, Mirzapur" className="img-fluid" /></LazyLoad>

                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                        <div class="labs-box">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/Bio-Lab1.jpg" alt="St Mary School, Mirzapur" className="img-fluid" /></LazyLoad>

                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                        <div class="labs-box">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/Bio-Lab2.jpg" alt="St Mary School, Mirzapur" className="img-fluid" /></LazyLoad>

                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                        <div class="labs-box">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/Bio-Lab3.jpg" alt="St Mary School, Mirzapur" className="img-fluid" /></LazyLoad>

                                        </div>
                                    </div>

                                </div>

                            )
                            }
                            {selectedTab === "chemistry" && (
                                <div className="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-4 text-center">
                                        <h3>Chemistry  Lab</h3>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                        <div class="labs-box">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/ChemistryLab.jpg" alt="St Mary School, Mirzapur" className="img-fluid" /></LazyLoad>

                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                        <div class="labs-box">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/ChemistryLab1.jpg" alt="St Mary School, Mirzapur" className="img-fluid" /></LazyLoad>

                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                        <div class="labs-box">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/ChemistryLab2.jpg" alt="St Mary School, Mirzapur" className="img-fluid" /></LazyLoad>

                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                        <div class="labs-box">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/ChemistryLab3.jpg" alt="St Mary School, Mirzapur" className="img-fluid" /></LazyLoad>

                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                        <div class="labs-box">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/ChemistryLab4.jpg" alt="St Mary School, Mirzapur" className="img-fluid" /></LazyLoad>

                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                        <div class="labs-box">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/ChemistryLab5.jpg" alt="St Mary School, Mirzapur" className="img-fluid" /></LazyLoad>

                                        </div>
                                    </div>

                                </div>
                            )}

                            {selectedTab === "physics" && (
                                <div className="row">
                                    <div className="col-md-12 mb-4 text-center">
                                        <h3>Physics Lab</h3>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                        <div class="labs-box">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/Physics-Lab.jpg" alt="St Mary School, Mirzapur" className="img-fluid" /></LazyLoad>

                                        </div>
                                    </div>
                                </div>
                            )}
                            {selectedTab === "jamecomputer" && (
                                <div className="row">
                                    <div className="col-md-12 mb-4 text-center">
                                        <h3>St. Jame's Computer Lab</h3>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                        <div class="labs-box">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/Computer-lab-1.jpg" alt="St Mary School, Mirzapur" className="img-fluid" /></LazyLoad>

                                        </div>
                                    </div>
                                </div>
                            )}

                            {selectedTab === "johncomputer" && (
                                <div className="row">
                                    <div className="col-md-12 mb-4 text-center">
                                        <h3>St. John's Computer Lab</h3>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                        <div class="labs-box">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/Computer-lab-2.jpg" alt="St Mary School, Mirzapur" className="img-fluid" /></LazyLoad>

                                        </div>
                                    </div>
                                </div>
                            )}

                            {selectedTab === "petercomputer" && (
                                <div className="row">
                                    <div className="col-md-12 mb-4 text-center">
                                        <h3>St. Peter's Computer Lab</h3>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                        <div class="labs-box">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/Computer-lab-3.jpg" alt="St Mary School, Mirzapur" className="img-fluid" /></LazyLoad>

                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                        <div class="labs-box">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/Computer-lab-4.jpg" alt="St Mary School, Mirzapur" className="img-fluid" /></LazyLoad>

                                        </div>
                                    </div>
                                </div>
                            )}
                            {selectedTab === "franciscomputer" && (
                                <div className="row">
                                    <div className="col-md-12 mb-4 text-center">
                                        <h3>St. Francis' Computer Lab</h3>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                        <div class="labs-box">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/Computer-lab-5.jpg" alt="St Mary School, Mirzapur" className="img-fluid" /></LazyLoad>

                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                        <div class="labs-box">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/Computer-lab-6.jpg" alt="St Mary School, Mirzapur" className="img-fluid" /></LazyLoad>

                                        </div>
                                    </div>
                                </div>
                            )}


                        </div>
                    </div>
                </div>
                </div>
                <Footer />
            </>
            )
}
            export default Laboratory