import React, { useEffect, useState } from 'react'
import { getOnlineRegistration } from '../Service/Api';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom'

function OnlineReg() {


    
  const [data, setData] = useState([]);

  useEffect(()=>{
    const getData =async ()=>{
      try {
        let registrationData = await getOnlineRegistration()
        setData(registrationData);

      } catch (error) {
        console.log(error)
      }
    }

    getData()
  },[])


  useEffect(() => {
    console.log("events_data", data);
}, [data]);


return (
    <div style={{ display: data.length > 0 ? 'block' : 'none' }}>
      <div className='online-reg'>
        <Link to='https://smm.campuscare.info/logon/onlineregistration' target='_blank' rel="noreferrer"> 
          <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/online-rgistration.png" alt="Online Registration" /></LazyLoad>
        </Link>
        {data.length > 0 && data[0]?.attachments?.length > 0 && (
          <Link to={`https://webapi.entab.info/api/image?url=${data[0].attachments[0]}`} className='instructions' target="_blank"  rel="noreferrer">
            <i className="bi bi-paperclip"></i> Read Instructions
          </Link>
        )}
      </div>
    </div>
  );
  
  
}

export default OnlineReg